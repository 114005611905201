import _ from "lodash";

import { jobAPI } from "@/api.js";
import showAPIErrorToast from '@/utils/showAPIErrorToast';

const handleErrorResponse = (err, dispatch, disableRequestIndicator = false) => {
  if (!disableRequestIndicator) {
    showAPIErrorToast(err);
    dispatch("SET_API_RESPONSE_STATUS", 'error')
  }
};

const state = {
  jobs: [],
  job: null,
  apiResponseStatus: '',
};

const getters = {
  jobs: (state) => state.jobs,
  job: (state) => state.job,
  apiResponseStatus: ({ apiResponseStatus }) => apiResponseStatus
};

const mutations = {
  SET_JOBS(state, jobs) {
    state.jobs = jobs
  },
  SET_JOB(state, job) {
    state.job = job
  },
  SET_API_RESPONSE_STATUS(state, apiResponseStatus) {
    state.apiResponseStatus = apiResponseStatus;
  },
};

const actions = {
  async FETCH_JOBS({ dispatch, commit, state, rootState }) {
    dispatch("SET_API_RESPONSE_STATUS", 'pending');
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    jobAPI
      .get(
        `${jobAPI.defaults.endpoints.getJobs}?createdBy=${rootState.app.user._id}`,
        options
      )
      .then(({ data }) => {
        dispatch("SET_JOBS", data);
        dispatch("SET_API_RESPONSE_STATUS", 'success');
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      });
  },
  async FETCH_JOBS_BY_PARENT_USER({ dispatch, commit, state, rootState }) {
    dispatch("SET_API_RESPONSE_STATUS", 'pending');
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };

    jobAPI
      .get(
        `${jobAPI.defaults.endpoints.getJobs}parentuser?parentUserId=${!rootState.app.user.is_child ? rootState.app.user._id : rootState.app.user.parent_user_id}`,
        options
      )
      .then(({ data }) => {
        dispatch("SET_JOBS", data);
        dispatch("SET_API_RESPONSE_STATUS", 'success');
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      });
  },
  async FETCH_JOB({ dispatch, commit, state, rootState }, jobId) {
    console.log("jonb id", jobId);
    dispatch("SET_API_RESPONSE_STATUS", 'pending');
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    await jobAPI
      .get(
        `${jobAPI.defaults.endpoints.getJobDetails}${jobId}`,
        options
      )
      .then(({ data }) => {
        console.log("job", data);
        dispatch("SET_JOB", data);
        dispatch("SET_API_RESPONSE_STATUS", 'success');
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      });
  },
  SET_JOBS({ commit }, jobs) {
    commit("SET_JOBS", jobs);
  },
  SET_JOB({ commit }, job) {
    commit("SET_JOB", job);
  },
  SET_API_RESPONSE_STATUS({ commit }, apiResponseStatus) {
    commit('SET_API_RESPONSE_STATUS', apiResponseStatus);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
