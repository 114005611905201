import _ from "lodash";

import { talentProfileAPI } from "@/api.js";
import showAPIErrorToast from "@/utils/showAPIErrorToast";

const handleErrorResponse = (
  err,
  dispatch,
  disableRequestIndicator = false
) => {
  if (!disableRequestIndicator) {
    showAPIErrorToast(err);
    dispatch("SET_API_RESPONSE_STATUS", "error");
  }
};

const state = {
  id: null,
  email: "",
  firstName: "",
  lastName: "",
  gender: "",
  ethnicGroups: [],
  lgbtqiaIdentities: [],
  isResidentOfUs: false,
  location: "",
  workPreferences: [],
  openToRelocation: true,
  locationsOfInterest: [],
  workEnvironments: [],
  highestLevelOfEducation: "",
  qualifications: [],
  hasUsGovernmentSecurityClearance: false,
  levelOfClearance: [],
  photoUrl: "",
  aboutMe: "",
  professionalExperiences: [],
  industries: [],
  jobFunctions: [],
  skills: [],
  resumePath: "",
  resumeImages: [],
  linkedinUrl: "",
  fetchingProfile: true,
  apiResponseStatus: "",
  firstTimeModalViewed: true,
  resumeParsed: true,
};

const getters = {
  profile: (state) => state,
  firstName: ({ firstName }) => firstName,
  lastName: ({ lastName }) => lastName,
  email: ({ email }) => email,
  fetchingProfile: ({ fetchingProfile }) => fetchingProfile,
  gender: ({ gender }) => gender,
  lgbtqiaIdentities: ({ lgbtqiaIdentities }) => lgbtqiaIdentities,
  isResidentOfUs: ({ isResidentOfUs }) => isResidentOfUs,
  location: ({ location }) => location,
  workPreferences: ({ workPreferences }) => workPreferences,
  locationsOfInterest: ({ locationsOfInterest }) => locationsOfInterest,
  workEnvironments: ({ workEnvironments }) => workEnvironments,
  highestLevelOfEducation: ({ highestLevelOfEducation }) =>
    highestLevelOfEducation,
  qualifications: ({ qualifications }) => qualifications,
  hasUsGovernmentSecurityClearance: ({ hasUsGovernmentSecurityClearance }) =>
    hasUsGovernmentSecurityClearance,
  levelOfClearance: ({ levelOfClearance }) => levelOfClearance,
  photoUrl: ({ photoUrl }) => photoUrl,
  aboutMe: ({ aboutMe }) => aboutMe,
  professionalExperiences: ({ professionalExperiences }) =>
    professionalExperiences,
  industries: ({ industries }) => industries,
  jobFunctions: ({ jobFunctions }) => jobFunctions,
  resumePath: ({ resumePath }) => resumePath,
  linkedinUrl: ({ linkedinUrl }) => linkedinUrl,
  ethnicGroups: ({ ethnicGroups }) => ethnicGroups,
  openToRelocation: ({ openToRelocation }) => openToRelocation,
  firstTimeModalViewed: ({ firstTimeModalViewed }) => firstTimeModalViewed,
  resumeParsed: ({ resumeParsed }) => resumeParsed,
  basicInfo: (state) => {
    const { photoUrl, firstName, lastName, email, aboutMe } = state;

    const basicInfoProps = {
      photoUrl,
      firstName,
      lastName,
      email,
      aboutMe,
    };

    return basicInfoProps;
  },
  professionalExperience: ({ professionalExperiences }) =>
    professionalExperiences,
  skills: ({ skills }) => skills,
  personalIdentities: (state) => {
    const personalIdentitiesProps = ({
      gender,
      lgbtqiaIdentities,
      ethnicGroups,
    } = state);

    return personalIdentitiesProps;
  },
  workPreference: (state) => {
    const interestedOpportunities = state.workPreferences;
    const workPreferencesProps = ({
      interestedOpportunities,
      locationsOfInterest,
      workEnvironments,
    } = state);

    return workPreferencesProps;
  },
  qualificationsSection: (state) => {
    const qualificationsProps = ({
      highestLevelOfEducation,
      qualifications,
      hasUsGovernmentSecurityClearance,
      levelOfClearance,
    } = state);
  },
  apiResponseStatus: ({ apiResponseStatus }) => apiResponseStatus,
};

const mutations = {
  SET_PROFILE(state, profile) {
    _.assign(state, profile);
  },
  SET_ID(state, id) {
    state.id = id;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_FIRST_NAME(state, firstName) {
    state.firstName = firstName;
  },
  SET_LAST_NAME(state, lastName) {
    state.lastName = lastName;
  },
  SET_GENDER(state, gender) {
    state.gender = gender;
  },
  SET_ETHNIC_GROUPS(state, ethnicGroups) {
    state.ethnicGroups = ethnicGroups;
  },
  SET_LGBTQIA_IDENTITIES(state, lgbtqiaIdentities) {
    state.lgbtqiaIdentities = lgbtqiaIdentities;
  },
  SET_US_RESIDENT(state, isResidentOfUs) {
    state.isResidentOfUs = isResidentOfUs;
  },
  SET_LOCATION(state, location) {
    state.location = location;
  },
  SET_WORK_PREFERENCES(state, workPreferences) {
    state.workPreferences = workPreferences;
  },
  SET_LOCATIONS_OF_INTEREST(state, locationsOfInterest) {
    state.locationsOfInterest = locationsOfInterest;
  },
  SET_WORK_ENVIRONMENTS(state, workEnvironments) {
    state.workEnvironments = workEnvironments;
  },
  SET_HIGHEST_EDUCATION(state, highestLevelOfEducation) {
    state.highestLevelOfEducation = highestLevelOfEducation;
  },
  SET_QUALIFICATIONS(state, qualifications) {
    state.qualifications = qualifications;
  },
  SET_US_GOVERNMENT_SECURITY_CLEARANCE(
    state,
    hasUsGovernmentSecurityClearance
  ) {
    state.hasUsGovernmentSecurityClearance = hasUsGovernmentSecurityClearance;
  },
  SET_LEVEL_OF_CLEARANCE(state, levelOfClearance) {
    state.levelOfClearance = levelOfClearance;
  },
  SET_PHOTO_URL(state, photoUrl) {
    state.photoUrl = photoUrl;
  },
  SET_ABOUT_ME(state, aboutMe) {
    state.aboutMe = aboutMe;
  },
  SET_PROFESSIONAL_EXPERIENCES(state, professionalExperiences) {
    state.professionalExperiences = professionalExperiences;
  },
  SET_INDUSTRIES(state, industries) {
    state.industries = industries;
  },
  SET_JOB_FUNCTIONS(state, jobFunctions) {
    state.jobFunctions = jobFunctions;
  },
  SET_RESUME_PATH(state, resumePath) {
    state.resumePath = resumePath;
  },
  SET_LINKEDIN_URL(state, linkedinUrl) {
    state.linkedinUrl = linkedinUrl;
  },
  SET_FETCHING_PROFILE(state, fetchingProfile) {
    state.fetchingProfile = fetchingProfile;
  },
  SET_OPEN_TO_RELOCATION(state, openToRelocation) {
    state.openToRelocation = openToRelocation;
  },
  SET_API_RESPONSE_STATUS(state, apiResponseStatus) {
    state.apiResponseStatus = apiResponseStatus;
  },
  SET_FIRST_TIME_MODAL_VIEWED(state, firstTimeModalViewed) {
    state.firstTimeModalViewed = firstTimeModalViewed;
  },
  SET_RESUME_PARSED(state, resumeParsed) {
    state.resumeParsed = resumeParsed;
  },
};

const actions = {
  async FETCH_PROFILE({ dispatch, commit, state, rootState }) {
    dispatch("SET_FETCHING_PROFILE", true);
    let waitUntilResumeParsed = localStorage.getItem("waitUntilResumeParsed");
    if (waitUntilResumeParsed) {
      waitUntilResumeParsed = JSON.parse(waitUntilResumeParsed);
      dispatch("SET_RESUME_PARSED", false);
    }
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    await talentProfileAPI
      .get(
        `${talentProfileAPI.defaults.endpoints.getProfile}${rootState.app.talentUserId}?wait_until_resume_parsed=${waitUntilResumeParsed}`,
        options
      )
      .then(({ data }) => {
        console.log(data, "updated sprofile");
        localStorage.removeItem("waitUntilResumeParsed");
        const camelCaseData = _.mapKeys(data, (value, key) => _.camelCase(key));
        console.log(camelCaseData, "camel");
        const updatedProfile = _.pick(
          _.cloneDeep(camelCaseData),
          _.keys(state)
        );
        if (waitUntilResumeParsed) updatedProfile.resumeParsed = false;
        else updatedProfile.resumeParsed = true;
        console.log(updatedProfile, "updated sprofile");
        dispatch("SET_PROFILE", updatedProfile);
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      })
      .finally(() => {
        dispatch("SET_FETCHING_PROFILE", false);
      });
  },
  async PATCH_UPDATE_PROFILE({ dispatch, state, rootState }, patch) {
    const { disableRequestIndicator } = patch;

    patch = _.omit(patch, ["disableRequestIndicator"]);

    if (!disableRequestIndicator) {
      dispatch("SET_API_RESPONSE_STATUS", "pending");
    }
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };

    const transformedPatch = {};

    Object.entries(patch).forEach(([key, value]) => {
      transformedPatch[_.snakeCase(key)] = value;
    });

    await talentProfileAPI
      .patch(
        `${talentProfileAPI.defaults.endpoints.patchProfile}${rootState.app.talentUserId}`,
        transformedPatch,
        options
      )
      .then(({ data }) => {
        const camelCaseData = _.mapKeys(data, (value, key) => _.camelCase(key));
        const updatedProfile = _.pick(
          _.cloneDeep(camelCaseData),
          _.keys(state)
        );
        dispatch("SET_PROFILE", updatedProfile);
        if (!disableRequestIndicator) {
          dispatch("SET_API_RESPONSE_STATUS", "success");
        }
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch, disableRequestIndicator);
      });
  },
  async ADD_NEW_EXPERIENCE({ dispatch, state, rootState }, experience) {
    console.log(experience, "experience");
    dispatch("SET_API_RESPONSE_STATUS", "pending");
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    const transformedExperience = {};

    Object.entries(experience).forEach(([key, value]) => {
      transformedExperience[_.snakeCase(key)] = value;
    });
    await talentProfileAPI
      .post(
        `${talentProfileAPI.defaults.endpoints.addProfessionalExperience}${rootState.app.talentUserId}`,
        transformedExperience,
        options
      )
      .then(({ data }) => {
        console.log(data, "fetched Profile");
        const camelCaseData = _.mapKeys(data, (value, key) => _.camelCase(key));
        console.log(camelCaseData, "camel");
        const updatedProfile = _.pick(
          _.cloneDeep(camelCaseData),
          _.keys(state)
        );
        console.log(updatedProfile, "updated profile");
        dispatch("SET_PROFILE", updatedProfile);
        dispatch("SET_API_RESPONSE_STATUS", "success");
      })
      .catch((err) => {
        console.log("reached here");
        handleErrorResponse(err, dispatch);
      });
  },
  async UPDATE_PROFESSIONAL_EXPERIENCE(
    { dispatch, commit, state, rootState },
    experience
  ) {
    console.log(experience, "experience");
    dispatch("SET_API_RESPONSE_STATUS", "pending");
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    const transformedExperience = {};

    Object.entries(experience).forEach(([key, value]) => {
      if (key !== "_id") transformedExperience[_.snakeCase(key)] = value;
      else transformedExperience[key] = value;
    });
    await talentProfileAPI
      .put(
        `${talentProfileAPI.defaults.endpoints.updateProfessionalExperience}${rootState.app.talentUserId}`,
        transformedExperience,
        options
      )
      .then(({ data }) => {
        console.log(data, "fetched Profile");
        const camelCaseData = _.mapKeys(data, (value, key) => _.camelCase(key));
        console.log(camelCaseData, "camel");
        const updatedProfile = _.pick(
          _.cloneDeep(camelCaseData),
          _.keys(state)
        );
        console.log(updatedProfile, "updated profile");
        dispatch("SET_PROFILE", updatedProfile);
        dispatch("SET_API_RESPONSE_STATUS", "success");
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      });
  },
  async DELETE_PROFESSIONAL_EXPERIENCE(
    { dispatch, commit, state, rootState },
    id
  ) {
    console.log(id, "experience id");
    dispatch("SET_API_RESPONSE_STATUS", "pending");
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    await talentProfileAPI
      .delete(
        `${talentProfileAPI.defaults.endpoints.getProfile}${rootState.app.talentUserId}/professionalExperience/${id}`,
        options
      )
      .then(({ data }) => {
        console.log(data, "fetched Profile");
        const camelCaseData = _.mapKeys(data, (value, key) => _.camelCase(key));
        console.log(camelCaseData, "camel");
        const updatedProfile = _.pick(
          _.cloneDeep(camelCaseData),
          _.keys(state)
        );
        console.log(updatedProfile, "updated profile");
        dispatch("SET_PROFILE", updatedProfile);
        dispatch("SET_API_RESPONSE_STATUS", "success");
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      });
  },
  async ADD_QUALIFICATION(
    { dispatch, commit, state, rootState },
    qualification
  ) {
    console.log(qualification, "qualification");
    dispatch("SET_API_RESPONSE_STATUS", "pending");
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    const transformedQualification = {};

    Object.entries(qualification).forEach(([key, value]) => {
      transformedQualification[_.snakeCase(key)] = value;
    });
    await talentProfileAPI
      .post(
        `${talentProfileAPI.defaults.endpoints.addQualification}${rootState.app.talentUserId}`,
        transformedQualification,
        options
      )
      .then(({ data }) => {
        console.log(data, "fetched Profile");
        const camelCaseData = _.mapKeys(data, (value, key) => _.camelCase(key));
        console.log(camelCaseData, "camel");
        const updatedProfile = _.pick(
          _.cloneDeep(camelCaseData),
          _.keys(state)
        );
        console.log(updatedProfile, "updated profile");
        dispatch("SET_PROFILE", updatedProfile);
        dispatch("SET_API_RESPONSE_STATUS", "success");
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      });
  },
  async UPDATE_QUALIFICATION(
    { dispatch, commit, state, rootState },
    qualification
  ) {
    console.log(qualification, "qualification");
    dispatch("SET_API_RESPONSE_STATUS", "pending");
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    const transformedQualification = {};

    Object.entries(qualification).forEach(([key, value]) => {
      if (key !== "_id") transformedQualification[_.snakeCase(key)] = value;
      else transformedQualification[key] = value;
    });
    await talentProfileAPI
      .put(
        `${talentProfileAPI.defaults.endpoints.updateQualification}${rootState.app.talentUserId}`,
        transformedQualification,
        options
      )
      .then(({ data }) => {
        console.log(data, "fetched Profile");
        const camelCaseData = _.mapKeys(data, (value, key) => _.camelCase(key));
        console.log(camelCaseData, "camel");
        const updatedProfile = _.pick(
          _.cloneDeep(camelCaseData),
          _.keys(state)
        );
        console.log(updatedProfile, "updated profile");
        dispatch("SET_PROFILE", updatedProfile);
        dispatch("SET_API_RESPONSE_STATUS", "success");
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      });
  },
  async DELETE_QUALIFICATION({ dispatch, commit, state, rootState }, id) {
    console.log(id, "qualification id");
    dispatch("SET_API_RESPONSE_STATUS", "pending");
    const options = {
      headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
    };
    await talentProfileAPI
      .delete(
        `${talentProfileAPI.defaults.endpoints.getProfile}${rootState.app.talentUserId}/qualification/${id}`,
        options
      )
      .then(({ data }) => {
        console.log(data, "fetched Profile");
        const camelCaseData = _.mapKeys(data, (value, key) => _.camelCase(key));
        console.log(camelCaseData, "camel");
        const updatedProfile = _.pick(
          _.cloneDeep(camelCaseData),
          _.keys(state)
        );
        console.log(updatedProfile, "updated profile");
        dispatch("SET_PROFILE", updatedProfile);
        dispatch("SET_API_RESPONSE_STATUS", "success");
      })
      .catch((err) => {
        handleErrorResponse(err, dispatch);
      });
  },
  SET_PROFILE({ commit }, profile) {
    commit("SET_PROFILE", profile);
  },
  SET_ID({ commit }, id) {
    commit("SET_ID", id);
  },
  SET_EMAIL({ commit }, email) {
    commit("SET_EMAIL", email);
  },
  SET_FIRST_NAME({ commit }, firstName) {
    commit("SET_FIRST_NAME", firstName);
  },
  SET_LAST_NAME({ commit }, lastName) {
    commit("SET_LAST_NAME", lastName);
  },
  SET_GENDER({ commit }, gender) {
    commit("SET_GENDER", gender);
  },
  SET_ETHNIC_GROUPS({ commit }, ethnicGroups) {
    commit("SET_ETHNIC_GROUPS", ethnicGroups);
  },
  SET_LGBTQIA_IDENTITIES({ commit }, lgbtqiaIdentities) {
    commit("SET_LGBTQIA_IDENTITIES", lgbtqiaIdentities);
  },
  SET_US_RESIDENT({ commit }, isResidentOfUs) {
    commit("SET_US_RESIDENT", isResidentOfUs);
  },
  SET_LOCATION({ commit }, location) {
    commit("SET_LOCATION", location);
  },
  SET_WORK_PREFERENCES({ commit }, workPreferences) {
    commit("SET_WORK_PREFERENCES", workPreferences);
  },
  SET_LOCATIONS_OF_INTEREST({ commit }, locationsOfInterest) {
    commit("SET_LOCATIONS_OF_INTEREST", locationsOfInterest);
  },
  SET_WORK_ENVIRONMENTS({ commit }, workEnvironments) {
    commit("SET_WORK_ENVIRONMENTS", workEnvironments);
  },
  SET_HIGHEST_EDUCATION({ commit }, highestLevelOfEducation) {
    commit("SET_HIGHEST_EDUCATION", highestLevelOfEducation);
  },
  SET_QUALIFICATIONS({ commit }, qualifications) {
    commit("SET_QUALIFICATIONS", qualifications);
  },
  SET_US_GOVERNMENT_SECURITY_CLEARANCE(
    { commit },
    hasUsGovernmentSecurityClearance
  ) {
    commit(
      "SET_US_GOVERNMENT_SECURITY_CLEARANCE",
      hasUsGovernmentSecurityClearance
    );
  },
  SET_LEVEL_OF_CLEARANCE({ commit }, levelOfClearance) {
    commit("SET_LEVEL_OF_CLEARANCE", levelOfClearance);
  },
  SET_PHOTO_URL({ commit }, photoUrl) {
    commit("SET_PHOTO_URL", photoUrl);
  },
  SET_ABOUT_ME({ commit }, aboutMe) {
    commit("SET_ABOUT_ME", aboutMe);
  },
  SET_PROFESSIONAL_EXPERIENCES({ commit }, professionalExperiences) {
    commit("SET_PROFESSIONAL_EXPERIENCES", professionalExperiences);
  },
  SET_INDUSTRIES({ commit }, industries) {
    commit("SET_INDUSTRIES", industries);
  },
  SET_JOB_FUNCTIONS({ commit }, jobFunctions) {
    commit("SET_JOB_FUNCTIONS", jobFunctions);
  },
  SET_RESUME_PATH({ commit }, resumePath) {
    commit("SET_RESUME_PATH", resumePath);
  },
  SET_LINKEDIN_URL({ commit }, linkedinUrl) {
    commit("SET_LINKEDIN_URL", linkedinUrl);
  },
  SET_TALENT_USER_ID({ commit }, talentUserId) {
    commit("SET_TALENT_USER_ID", talentUserId);
  },
  SET_FETCHING_PROFILE({ commit }, fetchingProfile) {
    commit("SET_FETCHING_PROFILE", fetchingProfile);
  },
  SET_OPEN_TO_RELOCATION({ commit }, openToRelocation) {
    commit("SET_OPEN_TO_RELOCATION", openToRelocation);
  },
  SET_API_RESPONSE_STATUS({ commit }, apiResponseStatus) {
    commit("SET_API_RESPONSE_STATUS", apiResponseStatus);
  },
  SET_FIRST_TIME_MODAL_VIEWED({ commit }, firstTimeModalViewed) {
    commit("SET_FIRST_TIME_MODAL_VIEWED", firstTimeModalViewed);
  },
  SET_RESUME_PARSED({ commit }, resumeParsed) {
    commit("SET_RESUME_PARSED", resumeParsed);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
