import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Talent Infusion'

let routes = [
  {
    path: '/',
    component: () => import('@/components/Auth/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        redirect: 'signup'
      },
      {
        path: 'signup',
        name: 'signup',
        component: () => import('@/components/Signup.vue'),
        meta: {
          alreadyLoggedIn: true
        }
      },
      {
        path: 'signin',
        name: 'signin',
        component: () => import('@/components/Signin.vue'),
        meta: {
          alreadyLoggedIn: true
        }
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/components/ForgotPassword.vue'),
      },
      {
        path: 'verification-code',
        name: 'verification-code',
        component: () => import('@/components/VerificationCode.vue'),
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: () => import('@/components/ResetPassword.vue'),
      },
      {
        path: 'reset-confirmation',
        name: 'reset-confirmation',
        component: () => import('@/components/ResetConfirmation.vue'),
      }
    ]
  },
  {
    path: '/account',
    component: () => import('@/components/Account/index.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'talent/details/:id',
        name: 'account-details',
        component: () => import('@/components/Account/Search/TalentDetailsPage.vue')
      },
      {
        path: '/',
        redirect: 'dashboard',
        component: () => import('@/components/Account/TemplateWithFilter.vue'),
        children: [
          {
            path: 'dashboard',
            name: 'account-dashboard',
            component: () => import('@/components/Account/Search/index.vue')
          },
          {
            path: 'search',
            name: 'account-search',
            component: () => import('@/components/Account/Search/index.vue')
          },
        ]
      },
      {
        path: 'settings',
        component: () => import('@/components/Account/Settings/index.vue'),
        children: [
          {
            path: '/',
            name: 'account-settings',
            component: () => import('@/components/Account/Settings/Edit.vue')
          },
          {
            path: 'payment-method',
            name: 'account-settings-payment-method',
            component: () => import('@/components/Account/Settings/PaymentMethod.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/jobs',
    component: () => import('@/components/Jobs/index.vue'),
    meta: {
    requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'jobs',
        component: () => import('@/components/Jobs/Jobs.vue'),
      },
      {
        path: 'details/:id',
        name: 'job-details',
        component: () => import('@/components/Jobs/Jobs.vue'),
      },
      {
        path: 'archived',
        name: 'archived-jobs',
        component: () => import('@/components/Jobs/Archived/index.vue')
      },
      {
        path: 'archived/details/:id',
        name: 'archived-job-details',
        component: () => import('@/components/Jobs/Archived/index.vue'),
      },
      {
        path: 'add',
        name: 'add-job',
        component: () => import('@/components/Jobs/Add/index.vue')
      },
    ]
  },
  {
    path: '/talent/profile',
    component: () => import('@/components/Profile/Talent'),
    meta: {
      alreadyLoggedIn: true
    },
  }
]
  routes = routes.concat([
    {
      path: "/talent/onboarding",
      name: "onboarding",
      component: () => import("@/components/Onboarding/Signup.vue"),
    },
    {
      path: "/talent/onboarding/uploadresume",
      name: "onboarding-uploadresume",
      component: () => import("@/components/Onboarding/UploadResume.vue"),
    },
    {
      path: "/talent/onboarding/createaccount",
      name: "onboarding-create-account",
      component: () => import("@/components/Onboarding/AccountCreation.vue"),
    },
    {
      path: "/talent/onboarding/verificationcode",
      name: "onboarding-verification-code",
      component: () => import("@/components/Onboarding/VerificationCode.vue"),
    },
    {
      path: '/talent/login',
      name: 'onboarding-login',
      component: () => import('@/components/Onboarding/Login.vue'),
    },
    {
      path: '/talent/forgot-password',
      name: 'onboarding-forgot-password',
      component: () => import('@/components/Onboarding/ForgotPassword.vue'),
    },
    {
      path: '/talent/codereset',
      name: 'onboarding-verification-code',
      component: () => import('@/components/Onboarding/CodeReset.vue'),
    },
    {
      path: '/talent/passwordreset',
      name: 'onboarding-password-reset',
      component: () => import('@/components/Onboarding/PasswordReset.vue'),
    }
  ]);
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {

  if (to.name === from.name) {
    return next()
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters['app/user']) {
    return next({
      name: 'signin'
    })
  }

  if (to.matched.some(record => record.meta.alreadyLoggedIn) && store.getters['app/user']) {
    return next({
      name: 'account-dashboard'
    })
  }
  next()
})

router.afterEach((to) => {
  Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
